class Module {
  init() {}

  reverse() {}

  restart() {}

  stop() {}

  destroy() {}
}

export default Module;
